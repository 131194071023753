import React from "react";
import { Layout } from "../components";
import { Link } from "gatsby";

const WinWinPage = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Filozofia współpracy z When, win-win digital agency",
        description:
          "W When nie sprzedajemy Ci naszych usług. Oferujemy Tobie partnerską współpracę w przyjaznej atmosferze nastawioną na obustronną korzyść w myśl filozofii win-win. Stawiamy na partnerstwo w sukcesie, wzajemny rozwój, obustronny zysk oraz partnerskie relacje.",
        href: pathname,
        lang: "pl",
      }}
    >
      <div className="subpage-header subpage-header--win-page">
        <h1 className="subpage-header__title">
          <span>win</span>-win <br /> digital agency
        </h1>
      </div>
      <section className="container-fluid win-section-1">
        <div className="page-header">
          <span>#when we win</span>
          <h2>Filozofia</h2>
        </div>
        <p className="win-section-1__desc">
          Twój zysk to nasz cel. W kampaniach digital liczą się przede wszystkim
          wyniki. <br />
          Filozofia win-win to jednak coś znacznie więcej niż tylko liczby.{" "}
          <br />W When nie sprzedajemy Ci naszych usług. Oferujemy Tobie
          partnerską współpracę w przyjaznej atmosferze nastawioną na obustronną
          korzyść.
        </p>
        <p className="win-section-1__bolded-text">
          Zaskoczymy Cię nie tylko w ROI!
        </p>

        <div className="columns-wrapper">
          <div className="row no-gutters">
            <div className="col-xl-3 col-4 offset-xl-1 columns-wrapper__left-col">
              <img
                className="img-fluid columns-wrapper__column-img win-hand"
                src={require("../assets/images/hand-win.png")}
                alt=""
              />
              <div className="inner inner--violet">
                <h3 className="inner__title">When</h3>
                <ul className="inner__list list">
                  <li>Nowe wyzwania</li>
                  <li>
                    Długotrwała <br /> współpraca
                  </li>
                  <li>
                    Wartościowe <br /> case studies
                  </li>
                  <li>
                    Polecenia do <br /> nowych klientów
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-4 columns-wrapper__mid-col">
              <img
                className="img-fluid columns-wrapper__column-img"
                src={require("../assets/images/handshake.png")}
                alt=""
              />
              <div className="inner inner--turqoise">
                <h3 className="inner__title title--white">
                  when <br /> <span>we win</span>
                </h3>
                <ul className="inner__list list--violet">
                  <li>
                    Partnerstwo <br /> w sukcesie!
                  </li>
                  <li>
                    Wzajemny <br /> rozwój
                  </li>
                  <li>
                    Obustronny <br /> zysk
                  </li>
                  <li>
                    Partnerskie <br /> relacje
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-4 columns-wrapper__right-col">
              <img
                className="img-fluid columns-wrapper__column-img win-hand"
                src={require("../assets/images/hand-win.png")}
                alt=""
              />
              <div className="inner inner--violet">
                <h3 className="inner__title">Klient</h3>
                <ul className="inner__list list--small-padding">
                  <li>Ekspercka wiedza</li>
                  <li>
                    Najnowsza <br /> technologia
                  </li>
                  <li>
                    Najwyższe <br /> standardy kampanii
                  </li>
                  <li>
                    Pełne <br /> zaangażowanie
                  </li>
                  <li>Zaufanie</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="win-section-2">
        <div className="page-header">
          <h2>
            Sprawdź trzy <br /> wymiary naszej wygranej
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-9 offset-xl-1 left-col">
            <div className="text-wrapper">
              <div className="text-wrapper__google-partner">
                <a
                  href="https://www.google.com/partners/agency?id=8558321192"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../assets/images/google-premier-partner.svg")}
                    alt=""
                  />
                </a>
              </div>
              <h3 className="text-wrapper__title">
                Zaawansowana <span>wiedza</span>
                <br /> i najnowsza technologia
              </h3>
              <p className="text-wrapper__desc">
                Nasza agencja posiada najwyższe odznaczenie agencyjne Google
                Partner Premier 2023, co oznacza, że należymy do 3% najlepszych
                agencji Google Ads w Polsce! Posiadamy dostęp do najnowszej
                wiedzy i rozwiązań technologicznych wspierających kampanie
                reklamowe. Jako partner o najwyższym odznaczeniu Google,
                posiadamy dostęp do najnowszych wersji beta usług, podsumowanie
                najnowszych trendów branżowych oraz uczestniczymy w
                najważniejszych wydarzeniach branżowych, panelach dyskusyjnych
                Google oraz spotkaniach z branżowymi liderami opinii. Nasz
                zespół składa się wyłącznie z doświadczonych, certyfikowanych
                specjalistów, którzy systematycznie aktualizują swoją wiedzę,
                wyszukują nowinek branżowych i dzielą się nimi, współtworząc
                środowisko wspierające wzajemny rozwój. Oznacza to, że kampania
                Twojej firmy będzie prowadzona wg. najnowszych i najwyższych
                możliwych standardów, a nasza wiedza na temat najnowszych
                możliwości reklamy internetowej oraz rozwoju Twojej kampanii
                jest stale aktualizowana.
              </p>
            </div>
            <div className="text-wrapper">
              <h3 className="text-wrapper__title">
                Imponujące <span>wyniki</span>
                <br />
              </h3>
              <p className="text-wrapper__desc">
                Skuteczność prowadzonych przez nas działań jest podstawą
                zaufania i stabilnej współpracy. Nasze kampanie pomogły już
                setkom firm rozwinąć swoją działalność w Polsce i w wielu
                przypadkach, pozwoliły również na szybką i skuteczną ekspansję
                na rynki zagraniczne. Planując strategię kampanii dobieramy
                kanały, które w jak najszybszym czasie pomagają firmom osiągać
                stabilny zwrot z poniesionej inwestycji, systematycznie skalując
                zysk z dalszych działań reklamowych. Nasze podejście sprawdza
                się i ma odzwierciedlenie w powstających relacjach biznesowych -
                obecnie 80% naszych nowych klientów przychodzi z polecenia osób,
                z którymi stale współpracujemy! <br />
                <br />{" "}
                <strong>
                  Zobacz przykładowe historie sukcesu naszych kampanii:
                </strong>
              </p>
              <div className="text-wrapper--inner-action">
                <Link to="/case-study/" className="btn btn-line">
                  case studies
                </Link>
              </div>
            </div>
            <div className="text-wrapper">
              <h3 className="text-wrapper__title">
                Partnerska <span>współpraca</span>
                <br />
              </h3>
              <p className="text-wrapper__desc">
                Wierzymy w koncepcję partnerskiej współpracy. Realizujemy ją
                dzięki przyjaznej kooperacji opartej na wspólnym zaangażowaniu i
                zaufaniu. Działamy w pełnej symbiozie i tak stajemy się częścią
                Twojej firmy, a Ty naszej. Twoje cele są naszymi celami, a nasze
                wyniki - Twoimi wynikami. Opieramy się na długotrwałej
                współpracy, która rozwija obie nasze firmy - wspólnie
                wygrywając. Będziemy partnerem i przyjacielem Twojego biznesu, a
                jako Grupa ADream - Twoim zewnętrznym działem marketingu.
                <br /> <br /> Nasza filozofia win-win nie odnosi się jednak
                wyłącznie do naszych relacji z klientami. Równie ważne są dla
                nas relacje wewnątrzagencyjne pomiędzy naszymi pracownikami i
                partnerami. Tylko w warunkach pełnego poczucia stabilności,
                bezpieczeństwa i sprawiedliwego wynagrodzenia za jakościową
                pracę, którą otrzymujemy, możemy skupić się w pełni na
                realizacji naszego celu, jakim jest rozwój firm naszych klientów
                poprzez skuteczny marketing internetowy.
              </p>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1 right-col">
            {/* <img
              className="img-fluid right-col__img"
              src={require("../assets/images/win-img.png")}
              alt=""
            /> */}
            <img
              className="img-fluid right-col__img img img--mobile"
              src={require("../assets/images/win-img-mobile.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default WinWinPage;
